import React from 'react'
import { Text } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */


export default props => { 
  return(
    <>
      <Seo title='Privacy Policy' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Privacy Policy'
          />
          <Divider />
                <Text variant='p'>
                www.manlycommunityforum.com ("the Site") recognises that privacy is very important to people.
                The Site has adopted this Privacy Policy as we recognise the right of our members, subscribers 
                and other stakeholders to keep their personal information private. This Privacy Policy covers 
                the Site's treatment of personally identifiable information that we collect or hold.      
                </Text>
                <Divider />
               <Section title='The information we collect'>
            
                <Text variant='p'>
                The Site will collect general information about you, such as your name, address, contact details,
                 and other relevant details when you subscribe to our newsletter or participate in other Site 
                 related activities. 
                 </Text>
                 <Text variant='p'>
                 Where practical, the purposes for which we collect personal information will be made clear 
                 at the time of collection. 
                 </Text>
                 <Text variant='p'>
                 You can generally visit the Site's website without revealing who they are or other personal 
                 information. The Site will not collect any personal information about visitors to the website 
                 except when they knowingly provide it.
                 </Text>
               </Section>
               <Divider />
               <Section title='How we use your information'>
               <Text variant='p'>
               The Site will use the personal information collected for the purpose disclosed at the time of collection, 
               or otherwise as set out in this Privacy Policy. We will not use your personal information for any other 
               purpose without first seeking your consent, unless authorised or required by law. 
               Generally we will only use and disclose your personal information as follows:
               </Text>
               <Text variant='p'>
               <ul>
                      <li>To establish and maintain communication, including providing you with newsletters, occasional relevant 
               news and other information necessary to inform you of the Site’s activities;</li>
               <li>to answer your questions or concerns</li>
               <li>to register you for our online meetings</li>
               </ul>

               </Text>
               </Section>
               <Divider />
               <Section title='Access to your information'>
               <Text variant='p'>
               You can access the personal information that the Site holds about you by contacting the Site's Privacy Officer
                as set out below. We will provide you with access unless we are legally authorised to refuse your request.
               If you wish to change personal information that is out of date or inaccurate at any time please contact us. 
               After notice from you, we will take reasonable steps to correct any of your information that is inaccurate, 
               incomplete or out of date.
               </Text>
               <Text variant='p'>
               If you wish to have your personal information deleted please let us know and we will delete that information 
               wherever practicable. We may refuse your request to access, amend or delete your personal information 
               in certain circumstances. If we do refuse your request, we will provide you with a reason for our decision 
               and, in the case of amendment, we will note with your personal information that you have disputed its accuracy.  
               </Text>
               </Section>
               <Divider />
               <Section title='Security'>
               <Text variant='p'>
               The Site will take reasonable steps to keep secure any personal information that we hold and to keep this 
               information accurate and up to date. Personal information is stored in a secure server or secure files. 
               The Internet is not generally a secure method of transmitting information. Accordingly, the Site cannot 
               accept responsibility for the security of information you send to or receive from us over the Internet 
               or for any unauthorised access or use of that information. The Site does, however, recognise that information
                will be passed via the Internet and takes all reasonable steps to minimise the risk associated with such communications.
               </Text>
               </Section>
               <Divider />
               <Section title='Changes to this Privacy Policy'>
               <Text variant='p'>
               The Site may amend this Privacy Policy from time to time by posting the amended version on our website at policy. 
               We suggest that you visit our website regularly to keep up to date with any changes. 
               </Text>
               </Section>
               <Divider />
               <Section title='Contacting us and "Opting Out" notification'>
               <Text variant='p'>
               If you would like any further information, or have any queries, problems or complaints relating to the Site's 
               Privacy Policy or our information handling practices in general, please contact our Privacy Officer by e-mail
               secretary@manlycommunityforum.com 
               </Text>
               </Section>
               
             
               
                 </Main>
      </Stack>
    </>
  )

}
